import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Link, Section, Seo, CommonBanner } from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";

const SitemapPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allDocsYaml {
          nodes {
            path
            title
          }
        }
        allBlogYaml {
          nodes {
            path
            title
          }
        }
        allKnowledgeYaml {
          nodes {
            title
            path
          }
        }
        allInfoYaml {
          nodes {
            path
            title
          }
        }
        allResourcesYaml {
          nodes {
            title
            url
          }
        }
      }
    `
  );

  const docs = data.allDocsYaml.nodes;
  const posts = data.allBlogYaml.nodes;
  const knowledges = data.allKnowledgeYaml.nodes;
  const resources = data.allResourcesYaml.nodes;
  
  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner title="Approov.io Sitemap" />

      <Section>
        <Container className="py-8">
          <div className="grid grid-cols-2 md:grid-cols-3 md:gap-8 xxs:grid-cols-1">
            <div>
              <h3 className="text-gray-800">Company</h3>
              <ul className="mt-4">

                <li>
                  <Link href="https://approov.io">Home Page</Link>
                </li>
                <li>
                  <Link href="/info/company/">About Us</Link>
                </li>
                <li>
                  <Link href="/info/contact">Contact Us</Link>
                </li>
                <li>
                    <Link href="/info/partners/">Partners and Integrations</Link>
                </li>
                <li>
                  <Link href="/info/news/">News</Link>
                </li>
                <li>
                    <Link href="/info/events/">Events</Link>
                </li>
                <li>
                    <Link href="/info/careers/">Careers</Link>
                </li>
                <li>
                    <Link href="/info/terms/">Terms</Link>
                </li>
                <li>
                  <Link href="/privacy/">Privacy</Link>
                </li>
                <li>
                  <Link href="/cookies/">Cookies</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-gray-800">Testimonials</h3>
              <ul className="mt-4">
                <li>
                  <Link href="/customer/">Overview</Link>
                </li>
                <li>
                  <Link href="/customer/bmw/">BMW</Link>
                </li>
                <li>
                  <Link href="/customer/deindeal/">Deindeal</Link>
                </li>
                <li>
                  <Link href="/customer/idyllic/">Idyllic</Link>
                </li>
                <li>
                  <Link href="/customer/mv/">MV</Link>
                </li>
                <li>
                  <Link href="/customer/metalpay/">MetalPay</Link>
                </li>
                <li>
                  <Link href="/customer/nimses/">Nimses</Link>
                </li>
                <li>
                  <Link href="/customer/papara/">Papara</Link>
                </li>
                <li>
                  <Link href="/customer/robotemi/">Robotemi</Link>
                </li>
                <li>
                  <Link href="/customer/scoffable/">Scoffable</Link>
                </li>
                <li>
                  <Link href="/customer/sixt/">Sixt</Link>
                </li>
                <li>
                  <Link href="/customer/felyx/">felyx</Link>
                </li>
                <li>
                  <Link href="/customer/genopets/">Genopets</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-gray-800">Features</h3>
              <ul className="mt-4">
                <li>
                  <Link href="/mobile-app-security/rasp/">RASP</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/app-attestation/">Mobile App Attestation</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/runtime-secrets">Runtime Secrets Protection</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/realtime-threat-intelligence">Real Time Threat Intelligence</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/api-security/">Secure API Authentication</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/dynamic-cert-pinning/">Dynamic Certificate Pinning</Link>
                </li>
                <li>
                  <Link href="/mobile-app-security/rasp/app-shielding">App Shielding</Link>
                </li>
                <li>
                  <Link href="/pricing/">Pricing</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-gray-800">Industries</h3>
              <ul className="mt-4">
                <li>
                  <Link href="/industry/mobile-finance/">Mobile Finance</Link>
                </li>
                <li>
                  <Link href="/market/mhealth/">mHealth</Link>
                </li>
                <li>
                  <Link href="/market/mobility/">Mobility</Link>
                </li>
                <li>
                  <Link href="/market/retail/">Retail</Link>
                </li>
                <li>
                  <Link href="/industry/mobile-gaming-and-gambling/">Mobile Gaming &
                      Gambling</Link>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-gray-800">Developer Docs</h3>
              <ul className="mt-4">
                <li>
                  <Link key="docs" href="https://approov.io/docs">
                    Approov Developer Docs
                  </Link>
                </li>
                {docs.map((doc) => (
                  <li>
                    <Link href={doc.path} hubspot="true">{doc.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div>
              <h3 className="text-gray-800">Resources</h3>
              <ul className="mt-4">
                  <li>
                      <Link href="/info/schedule-a-demo">Schedule a Demo</Link>
                  </li>
                  <li>
                      <Link href="/signup/">Free Trial Sign Up</Link>
                  </li>
                  <li>
                      <Link href="/tools/static-pinning/">Certificate Pinning Generator</Link>
                  </li>
                  {resources.map((post) => (
                      <li>
                          <Link href={post.url} hubspot="true">{post.title}</Link>
                      </li>
                  ))}
              </ul>
          </div>

          <div>
            <h3 className="text-gray-800">Blogs</h3>
            <ul className="mt-4">
              {posts.map((post) => (
                <li>
                  <Link href={post.path} hubspot="true">{post.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-gray-800">Knowledge Base</h3>
            <ul className="mt-4">
              {knowledges.map((post) => (
                <li>
                  <Link href={post.path} hubspot="true">{post.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default SitemapPage;
